import React from "react"
import logo from "./logo.svg"

import database from "./firebase/database"

import "./App.css"

function App() {
  console.log(database)
  var starCountRef = database.ref("times/pornography")
  starCountRef.on("value", function (snapshot) {
    console.log(snapshot.val())
    // updateStarCount(postElement, snapshot.val());
  })
  return (
    <div className="App">
      I hate to say it but we all lie too much and too often. If we're going to
      have any chance of saving our souls, we have to start telling the truth
      immediately. It can start big or small, but it has to start with you. I
      created this site so that it could start with me too.
    </div>
  )
}

export default App
