import firebase from "firebase"

// Set the configuration for your app
// TODO: Replace with your project's config object
var config = {
  authDomain: "radicalhonesty.us",
  databaseURL: "https://radical-honesty.firebaseio.com/",
}
firebase.initializeApp(config)

// Get a reference to the database service
var database = firebase.database()

export default database
